import { TextWithCTA } from '/features/buildingBlocks/TextWithCTA'
import { ImageCaption } from './ImageCaption'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'

import styles from './ImageWithContent.css'

export function ImageWithContent({ title, blocks, employees, internalOrExternalLink, image }) {
  return (
    <div className={styles.component}>
      <TextWithCTA {... { title, blocks, employees, internalOrExternalLink }} />
      <div className={styles.imageContainer}>
        <figure className={styles.image}>
          <ImageCover
            aspectRatio={5 / 6}
            layoutClassName={styles.imageLayout}
            {...{ image }}
          />
          {image?.caption && <ImageCaption layoutClassName={styles.captionLayout} caption={image?.caption} />}
        </figure>
      </div>
    </div>
  )
}
