import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'

import { ImageWithContent } from '/features/buildingBlocks/ImageWithContent'
import { TextWithCTA } from '/features/buildingBlocks/TextWithCTA'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'
import { ImageCaption } from '/features/buildingBlocks/ImageCaption'

import styles from './ImagesWithContentSlider.css'

export function ImagesWithContentSlider({ items }) {
  const isMounted = useRenderOnMount()
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)

  return (
    <div className={styles.component}>
      {isMounted && (
        isViewportLg
          ? <DesktopScrollingSlider {...{ items }} />
          : <MobileItems {...{ items }} />
      )}
    </div>
  )
}

function MobileItems({ items }) {
  return (
    items?.map(({ title, blocks, employees, internalOrExternalLink, image }, i) => (
      <div key={i} className={styles.componentMobileItems}>
        <ImageWithContent {...{ title, blocks, employees, internalOrExternalLink, image }} />
      </div>
    ))
  )
}

function DesktopScrollingSlider({ items }) {
  const imageRef = React.useRef(null)
  const [padding, setPadding] = React.useState(0)

  React.useEffect(
    () => {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)

      function handleResize() {
        const { height } = imageRef.current.getBoundingClientRect()
        const padding = (window.innerHeight - height) / 2

        setPadding(padding)
      }
    },
    [imageRef]
  )

  return (
    <div className={styles.componentDesktopScrollingSlider}>
      <div className={styles.contentContainer}>
        {items?.map(({ title, blocks, employees, internalOrExternalLink }, i) => (
          <ContentSlide key={i} layoutClassName={styles.contentSlideLayout} {...{ title, blocks, employees, internalOrExternalLink, padding }} />
        ))}
      </div>

      <div className={styles.imageContainer}>
        {items?.map((item, i) => (
          <ImageSlide
            key={i}
            image={item.image}
            layoutClassName={styles.imageSlideLayout}
            {...{ imageRef }}
          />
        ))}
      </div>
    </div>
  )
}

function ContentSlide({ title, blocks, employees, internalOrExternalLink, padding, layoutClassName = undefined }) {
  return (
    <div style={{ padding: `${padding}px 0` }} className={cx(styles.componentContentSlide, layoutClassName)}>
      <TextWithCTA layoutClassName={styles.textWithCTALayout} {...{ title, internalOrExternalLink, blocks, employees }} />
    </div>
  )
}

function ImageSlide({ image, imageRef, layoutClassName = undefined }) {
  const elementRef = React.useRef(null)
  const [y, setY] = React.useState(0)
  const isVisible = y <= 0

  React.useEffect(
    () => {
      handleScroll()
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)

      function handleScroll() {
        const { y } = elementRef.current.getBoundingClientRect()
        setY(y)
      }
    },
    []
  )

  return (
    <div ref={elementRef} className={cx(styles.componentImageSlide, isVisible && styles.isVisible, layoutClassName)}>
      <div className={styles.imageSlideContainer}>
        <figure ref={imageRef} className={styles.image}>
          <ImageCover aspectRatio={5 / 6} layoutClassName={styles.imageLayout} {...{ image }} />
          {image?.caption && (
            <ImageCaption layoutClassName={styles.captionLayout} caption={image.caption} />
          )}
        </figure>
      </div>
    </div>
  )
}
